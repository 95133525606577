import { Divider, Link, Stack, Typography } from '@mui/material';
import Iconify from '#/components/shared/ui/Iconify';
import { fDate } from '#/utils/formatTime';
import useLocales from '#/hooks/useLocales';

type Props = {
  signed_document: string;
  uploaded_at: string;
};

export default function DocumentHistoryItem({
  signed_document,
  uploaded_at,
}: Props) {
  const { translate, currentLang } = useLocales();
  const getFileName = (url: string) => url?.split('/').pop();

  const nameTruncate = (name: string | undefined) => {
    if (!name) return '';
    if (name?.length > 25) {
      return `${name?.substring(0, 25)}...`;
    }
    return name;
  };

  return (
    <Stack direction="row" alignItems="start" spacing={2} sx={{ width: 330 }}>
      <Stack direction="column" alignItems="center" spacing={1}>
        <Iconify icon="ci:circle" color="primary.main" width={24} height={20} />
        <Divider
          orientation="vertical"
          sx={{
            height: 70,
          }}
        />
      </Stack>
      <Stack>
        <Link href={signed_document} target="_blank" rel="noreferrer">
          <Typography
            variant="subtitle1"
            fontWeight="light"
            color="primary.main"
            width={1}
          >
            {nameTruncate(getFileName(signed_document))}
            <Iconify
              icon="grommet-icons:share"
              color="primary.main"
              sx={{ marginLeft: 2 }}
            />
          </Typography>
        </Link>

        <Typography
          variant="subtitle2"
          fontWeight="light"
          color="text.secondary"
        >
          {fDate(uploaded_at, 'dd. MMM yyyy', currentLang.value)}
        </Typography>
      </Stack>
    </Stack>
  );
}
